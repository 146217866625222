import { isEmpty, isError, isUndefined, noop } from 'lodash-es'

function isPromise(something) {
  return something && typeof something.then === 'function'
}

function handleSuccess(verboseReporter, explicitName, result) {
  const verboseArgs = isUndefined(result)
    ? [`[wix-dataset.${explicitName}] returned`]
    : [
        `[wix-dataset.${explicitName}] returned with (`,
        JSON.stringify(result),
        `)`
      ]
  verboseReporter(...verboseArgs)
}

const nullVerboseReporter = noop
const callbacksRegistration = [
  'onReady',
  'onError',
  'onBeforeSave',
  'onAfterSave',
  'onCurrentIndexChanged',
  'onItemValuesChanged'
]
const verboseWrapper = (verboseReporter, func, explicitName) => (...args) => {
  if (callbacksRegistration.includes(explicitName)) {
    verboseReporter(`[${explicitName} callback registered] on wix-dataset`)
    return func(...args)
  }

  const verboseArgs =
    args.length === 0
      ? [`[wix-dataset.${explicitName}] called`]
      : [
          `[wix-dataset.${explicitName}] called with (`,
          JSON.stringify(args),
          `)`
        ]
  verboseReporter(...verboseArgs)

  const result = func(...args)
  if (isPromise(result)) {
    return result.then(result => {
      handleSuccess(verboseReporter, explicitName, result)
      return result
    })
  }
  handleSuccess(verboseReporter, explicitName, result)
  return result
}

const shouldWrapWithVerbose = verboseReporter =>
  verboseReporter !== nullVerboseReporter

const verboseEvent = (verboseReporter, eventName, ...eventData) => {
  if (isEmpty(eventData)) {
    verboseReporter(`[${eventName} event] triggered on wix-dataset`)
  } else {
    const stringifiedErrors = eventData.map(datum =>
      isError(datum) ? datum.toString() : datum
    )
    verboseReporter(
      `[${eventName} event] triggered on wix-dataset with (`,
      JSON.stringify(stringifiedErrors),
      `)`
    )
  }
}

export {
  verboseWrapper,
  shouldWrapWithVerbose,
  verboseEvent,
  nullVerboseReporter
}

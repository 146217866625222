'use strict'

import { noop } from 'lodash-es'
import { union } from '@wix/wix-code-adt'
import { matchAny } from '@wix/wix-code-client-logger'
import sendBiOnError from '@wix/dbsm-common/src/bi/sendBiOnError'
import { setupBiLogger, setupErrorBiLogger } from '../bi/setupBiLogger'
import getDefaultBIParams from '../bi/getDefaults'
import { errorBoundaryScopes } from '../error-boundaries/errorBoundaries'

const { USER_SCOPE } = errorBoundaryScopes

const Environment = union('Environment', {
  NotInitialized: () => {},
  SSR: () => {},
  Client: ({ viewMode, platformBiParams, biLoggerFactory }) => {
    const biLogger = setupBiLogger(
      viewMode,
      getDefaultBIParams(platformBiParams, viewMode),
      biLoggerFactory
    )
    const errorBiLogger = setupErrorBiLogger(biLoggerFactory)
    return { biLogger, errorBiLogger }
  }
})

const biHandlerCreator = () => {
  let environment = Environment.NotInitialized()

  const biHandler = () => ({
    init: ({ inSsr, viewMode, platformBiParams, biLoggerFactory }) => {
      environment = inSsr
        ? Environment.SSR()
        : Environment.Client({ viewMode, platformBiParams, biLoggerFactory })
    },
    log: logEvent => {
      logEvent.matchWith({
        BI: ({ biEvent }) => {
          environment.matchWith({
            Client: ({ biLogger }) => biLogger.log(biEvent),
            SSR: noop,
            NotInitialized: () => {
              throw new Error(
                `You cannot report to BI before setting the logger environment.
                  Make sure you call logger.init before reporting.`
              )
            }
          })
        },
        Error: ({ error, options: { zone } }) => {
          environment.matchWith({
            Client: ({ errorBiLogger }) => {
              if (zone !== USER_SCOPE) {
                sendBiOnError(event => errorBiLogger.log(event), error)
              }
            },
            [union.any]: noop
          })
        },
        [matchAny]: () => {}
      })
    }
  })

  return biHandler
}

export { biHandlerCreator }

'use strict'

import { isEmpty, forEach, orderBy } from 'lodash-es'
import { getFieldValue } from './helpers'
import { transformFromRecordToView } from '../transformData'
import {
  getFieldReferencedCollection,
  getSchemaDisplayField
} from '../../schemas/helpers'
import baseAdapter from './baseAdapter'

const adapter = ({
  getState,
  getFieldType,
  getSchema,
  wixDataProxy,
  databindingVerboseReporter
}) => {
  const createOption = (record, fieldName, dataTransformer) => {
    const value = dataTransformer(getFieldValue(record, fieldName))
    return {
      value,
      label: value || '' //TODO: probably don't need this check
    }
  }

  const createOptionForReference = (record, displayField, dataTransformer) => ({
    value: dataTransformer(record._id),
    label: dataTransformer(record[displayField])
  })

  const fetchDropdownOptions = async (fieldName, actions, dataTransformer) => {
    const isReference = getFieldType(fieldName)
      .map(fieldType => fieldType === 'reference')
      .getOrElse(false)

    if (isReference) {
      return getSchema()
        .chain(schema => {
          const refCollection = getFieldReferencedCollection(fieldName, schema)
          return getSchema(refCollection)
            .map(getSchemaDisplayField)
            .map(async displayField => {
              const { items } = await actions.fetchAll(fieldName)

              const options = orderBy(
                items.map(record =>
                  createOptionForReference(
                    record,
                    displayField,
                    dataTransformer
                  )
                ),
                [option => option.label.toLowerCase()]
              )

              return options
            })
        })
        .getOrElse(Promise.resolve([]))
    } else {
      const { items } = await actions.fetchAll()

      return items.map(record =>
        createOption(record, fieldName, dataTransformer)
      )
    }
  }

  const handleSingleEmptyOption = options => {
    const firstOption = options[0]
    if (
      options.length === 1 &&
      firstOption.label === '' &&
      firstOption.value === ''
    ) {
      return []
    }
    return options
  }

  const getDropdownOptions = async (fieldName, actions, dataTransformer) =>
    handleSingleEmptyOption(
      await fetchDropdownOptions(fieldName, actions, dataTransformer)
    )

  const updateComponent = async (
    { connectionConfig: { properties }, component, role },
    actions
  ) => {
    // to allow casting component to any
    // prettier-ignore
    const dropdown = (component)
    if (properties.value && properties.value.fieldName) {
      const options = await getDropdownOptions(
        properties.value.fieldName,
        actions,
        value => transformFromRecordToView({ value, role })
      )
      databindingVerboseReporter.logValue({
        component,
        valueDescription: { options }
      })
      dropdown.options = options
    }
  }

  const logVerboseForBinding = (component, connectionConfig) => {
    const { properties } = connectionConfig
    const bindingDescription = {}

    forEach(properties, ({ fieldName }) => {
      bindingDescription.options = fieldName
    })

    databindingVerboseReporter.logBinding({
      component,
      bindingDescription
    })
  }

  return {
    ...baseAdapter,

    clearComponent({ component }) {
      component.options = []
    },

    isValidContext({ connectionConfig: { properties } }) {
      return !isEmpty(properties)
    },

    bindToComponent({ component, connectionConfig }) {
      logVerboseForBinding(component, connectionConfig)
    },

    currentRecordModified: updateComponent,
    recordSetLoaded: updateComponent
  }
}

export default adapter

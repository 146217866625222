'use strict'

import '../helpers/polyfills'
import {
  get,
  values,
  includes,
  merge,
  defaultsDeep,
  noop,
  pick,
  uniq,
  without,
  isEmpty,
  flatten,
  difference
} from 'lodash-es'
import DATASET_TYPES from '@wix/wix-data-client-common/src/datasetTypes'
import { convertFromCustomFormat } from '@wix/cloud-elementory-protocol'
import defaultDatasetConfiguration from '@wix/wix-data-client-common/src/dataset-configuration/defaults'
import { parseUrlPattern } from '@wix/dbsm-common/src/dynamic-pages/urlUtils'
import schemaAPICreator from '../schemas/schemaAPI'
import getReferencedCollectionsNames from '../schemas/getReferencedCollectionsNames'
import wixDataProxyCreator from '../wix-data/wixDataProxy'
import { createRecordStoreService } from '../record-store'
import createControllerFactory from '../dataset-controller/controllerFactory'
import createDatabindingVerboseReporter from '../verbose/databindingVerboseReporter'
import { traceCreators } from '../logger'
import { TraceType } from '../logger/traceType'
import {
  isEnvEditor,
  isModePreview,
  isModeLivePreview
} from '../helpers/viewMode'
import { nullVerboseReporter } from '../dataset-api/verbosity'
import Deferred from '../helpers/Deferred'

const getSDK = controllerConfigs => controllerConfigs[0].wixCodeApi
const isSSRMode = controllerConfigs =>
  get(getSDK(controllerConfigs), ['window', 'rendering', 'env']) === 'backend'
const getViewMode = controllerConfigs =>
  get(getSDK(controllerConfigs), ['window', 'viewMode'])

const getDefaultFieldsSort = patternFields =>
  patternFields.map(field => ({ [field]: 'asc' }))

const getSortObject = sortArray =>
  sortArray.reduce(
    (accumulator, currentValue) => Object.assign(accumulator, currentValue),
    {}
  )

const extractDynamicUrlPatternFieldsValuesFromRecord = (
  dynamicUrl,
  record,
  sortFields,
  patternFields
) => {
  const sortAndPatternFields = patternFields.concat(sortFields)
  return patternFields.length ? pick(record, sortAndPatternFields) : null
}

const getDatasetSortFields = sort =>
  flatten(sort.map(sortItem => Object.keys(sortItem).map(key => key)))

const extractRouterPayload = (payload, parser) => {
  const { dynamicUrl, userDefinedFilter, items, totalCount, config } = payload
  const parsedItems = parser(items)
  const record = parsedItems ? parsedItems[0] : null
  const datasetSort = get(config, 'dataset.sort', []) || []
  const patternFields =
    dynamicUrl && record ? parseUrlPattern(dynamicUrl).fields : []
  const datasetSortFields = getDatasetSortFields(datasetSort)
  const unsortedPatternFields = difference(patternFields, datasetSortFields)
  const sort = getSortObject([
    ...datasetSort,
    ...getDefaultFieldsSort(unsortedPatternFields)
  ])
  const sortFields = [...datasetSortFields, ...unsortedPatternFields]

  const dynamicUrlPatternFieldsValues = extractDynamicUrlPatternFieldsValuesFromRecord(
    dynamicUrl,
    record,
    sortFields,
    patternFields
  )

  return {
    prefetchedData: {
      items: parsedItems,
      totalCount
    },
    dynamicPagesData: {
      dynamicUrl,
      userDefinedFilter,
      dynamicUrlPatternFieldsValues,
      sort,
      sortFields,
      patternFields
    }
  }
}

const mergeRouterDatasetConfig = (routerConfig, controllerConfig) =>
  merge({}, controllerConfig, routerConfig)

const extractPlatformControllerAPI = ({ pageReady, exports, dispose }) => ({
  pageReady,
  exports,
  dispose
})

export default ({
  wixDataCreator,
  errorReporter,
  verboseReporter: originalVerboseReporter,
  shouldVerbose,
  appLogger,
  automationsClientCreator
}) => {
  let wixDataProxy
  let wixDataSchemasProxy
  let routerPayload
  let schemaAPI
  let wixSdk
  let recordStoreCache
  let verboseReporter
  let automationsClient

  const completeControllerConfigs = controllerConfigs => {
    return controllerConfigs.map(controllerConfig => {
      const { config, type } = controllerConfig

      const mergedConfig =
        type === DATASET_TYPES.ROUTER_DATASET
          ? mergeRouterDatasetConfig(routerPayload.config, config)
          : config

      const datasetConfiguration = defaultsDeep({}, mergedConfig, {
        dataset: defaultDatasetConfiguration
      })

      return {
        ...controllerConfig,
        config: datasetConfiguration
      }
    })
  }

  const startLoadingSchemas = (controllerConfigs, modeIsSSR) => {
    const prefetchedSchemaData = null

    if (prefetchedSchemaData) {
      return schemaAPI.loadPrefetched(prefetchedSchemaData)
    }

    const collectionIds = getMainCollectionIds(controllerConfigs)

    if (
      collectionIds.length === 1 &&
      hasAllSchemasReferencedByCollection(
        collectionIds[0],
        routerPayload.schemas
      )
    ) {
      return schemaAPI.loadPrefetched(routerPayload.schemas)
    }

    return appLogger
      .traceAsync(traceCreators.loadSchemas(), () =>
        schemaAPI.loadSchemas(collectionIds)
      )
      .catch(() => {})
  }

  const hasAllSchemasReferencedByCollection = (collectionId, schemas) => {
    if (isEmpty(schemas)) {
      return false
    }

    const mainSchema = schemas[collectionId]
    if (isEmpty(mainSchema)) {
      return false
    }

    const referencedCollectionIds = getReferencedCollectionsNames(mainSchema)
    return (
      isEmpty(referencedCollectionIds) ||
      referencedCollectionIds.every(cid => !isEmpty(schemas[cid]))
    )
  }

  const getMainCollectionIds = controllerConfigs => {
    const ids = controllerConfigs.map(controllerConfig =>
      get(
        controllerConfig,
        [
          'config',
          'dataset',
          'collectionName' // is actually collectionId :(
        ],
        null
      )
    )
    return without(uniq(ids), null)
  }

  const app = {
    initAppForPage: (
      { routerReturnedData },
      _,
      _wixSdk,
      {
        bi = {},
        reportTrace = noop,
        monitoring: { createMonitor: createRavenClient },
        fedOpsLoggerFactory,
        biLoggerFactory
      } = {}
    ) => {
      try {
        wixSdk = _wixSdk
        recordStoreCache = {}

        const modeIsSsr =
          get(wixSdk, ['window', 'rendering', 'env']) === 'backend'
        const viewMode = get(wixSdk, ['window', 'viewMode'])
        verboseReporter =
          shouldVerbose && isModePreview(viewMode)
            ? originalVerboseReporter
            : nullVerboseReporter

        appLogger.addSessionData(() => ({ routerReturnedData }))
        appLogger.init({
          appLogger,
          user: {
            id: get(wixSdk, ['user', 'currentUser', 'id'])
          },
          inSsr: modeIsSsr,
          viewMode,
          platformBiParams: bi,
          browserUrlGetter: () => get(wixSdk, ['location', 'url']),
          reportTrace,
          createRavenClient,
          fedOpsLoggerFactory,
          biLoggerFactory
        })

        appLogger.traceSync(traceCreators.initAppForPage(), () => {
          ;({ wixDataProxy, wixDataSchemasProxy } = wixDataProxyCreator(
            appLogger.wixDataCodeZone,
            () =>
              wixDataCreator({
                baseUrl: wixSdk.location.baseUrl,
                envIsEditor: isEnvEditor(viewMode)
              })
          ))
          routerPayload = routerReturnedData || {}
          schemaAPI = schemaAPICreator(wixDataSchemasProxy, appLogger)
          automationsClient = automationsClientCreator(global.elementorySupport)
        })
        return Promise.resolve()
      } catch (e) {
        appLogger.error(e)
        return Promise.reject(e)
      }
    },
    createControllers: rawControllerConfigs => {
      return appLogger.traceSync(traceCreators.createControllers(), () => {
        try {
          if (rawControllerConfigs.length === 0) {
            return []
          }

          const controllerConfigs = completeControllerConfigs(
            rawControllerConfigs
          )
          const viewMode = getViewMode(controllerConfigs)
          const modeIsSSR = isSSRMode(controllerConfigs)

          startLoadingSchemas(controllerConfigs, modeIsSSR)

          const reportFormEventToAutomation = automationsClient.reportFormEventToAutomationCreator(
            {
              isPreview: isEnvEditor(viewMode)
            }
          )

          const datasetTypes = values(DATASET_TYPES)
          const instansiateDatabindingVerboseReporter = createDatabindingVerboseReporter(
            verboseReporter,
            shouldVerbose
          )
          const renderingControllers = []
          const {
            resolve: renderDeferredControllers,
            promise: renderingRegularControllers
          } = Deferred()

          const controllers = controllerConfigs.map(
            ({
              type,
              config,
              connections,
              $w,
              compId: datasetId,
              livePreviewOptions: {
                shouldFetchData: dataIsInvalidated,
                compsIdsToReset: updatedCompIds = []
              } = {},
              platformAPIs,
              wixCodeApi: wixSdk
            }) => {
              if (!includes(datasetTypes, type)) {
                throw new Error(
                  `type of controller MUST be one of ${datasetTypes} but is ${type}`
                )
              }

              appLogger.trace(
                TraceType.Breadcrumb({
                  level: 'info',
                  category: 'createControllers',
                  message: 'warmup data contents',
                  data: {
                    datasetId,
                    datasetType: type,
                    env: get(wixSdk, ['window', 'rendering', 'env']),
                    warmupData: false
                  }
                })
              )

              const routerData =
                type === DATASET_TYPES.ROUTER_DATASET
                  ? extractRouterPayload(routerPayload, convertFromCustomFormat)
                  : undefined

              const recordStoreService = createRecordStoreService({
                primaryDatasetId: datasetId,
                recordStoreCache,
                refreshStoreCache: dataIsInvalidated,
                wixDataProxy,
                warmupStore: undefined,
                controllerConfig: config,
                logger: appLogger
              })

              const {
                promise: renderingController,
                resolve: markControllerAsRendered
              } = Deferred()

              renderingControllers.push(renderingController)

              const controllerFactory = createControllerFactory(appLogger, {
                $w,
                controllerConfig: config,
                datasetType: type,
                connections,
                recordStoreService,
                wixDataProxy,
                firePlatformEvent: appLogger.userCodeZone($w.fireEvent),
                wixSdk,
                errorReporter,
                verboseReporter,
                instansiateDatabindingVerboseReporter,
                routerData,
                appLogger,
                datasetId,
                handshakes: [],
                schemaAPI,
                reportFormEventToAutomation,
                platformAPIs,
                updatedCompIds,
                markControllerAsRendered,
                renderingRegularControllers,
                // isModeLivePreview is ture only if the LivePreview feature is enabled,
                // since in other case the Viewer won't be loaded at all
                modeIsLivePreview: isModeLivePreview(viewMode),
                modeIsSSR
              })

              const datasetController = extractPlatformControllerAPI(
                controllerFactory.createPrimaryController()
              )
              return Promise.resolve(datasetController)
            }
          )

          Promise.all(renderingControllers).then(renderDeferredControllers)

          return controllers
        } catch (e) {
          appLogger.error(e)
          return []
        }
      })
    }
  }

  return app
}

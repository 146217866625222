'use strict'

import { get, flow } from 'lodash-es'
import { Maybe, Result } from '@wix/wix-code-adt'
import QueryResults from '../helpers/queryResults'
import { getFieldReferencedCollection } from '../schemas/helpers'
import { isSameRecord } from './records'
import recordStoreServiceCreator from './service'

const createRecordStoreService = ({
  primaryDatasetId,
  recordStoreCache,
  refreshStoreCache,
  wixDataProxy,
  warmupStore,
  controllerConfig,
  logger
}) => {
  const datasetConfig = Result.fromNullable(
    controllerConfig,
    'missing controller configuration'
  ).chain(({ dataset }) =>
    Result.fromNullable(
      dataset,
      'controller configuration is missing dataset object'
    )
  )
  const collectionName = datasetConfig.chain(({ collectionName }) =>
    Result.fromNullable(
      collectionName,
      'dataset is not connected to a collection'
    )
  )

  return collectionName.map(mainCollectionName => {
    const includes = get(controllerConfig, ['dataset', 'includes'])
    const readWriteType = get(controllerConfig, ['dataset', 'readWriteType'])

    return recordStoreServiceCreator({
      primaryDatasetId,
      recordStoreCache,
      refreshStoreCache,
      wixDataProxy,
      warmupStore,
      mainCollectionName,
      includes,
      readWriteType,
      logger
    })
  })
}

const createRecordStoreInstance = ({
  recordStoreService,
  getFilter,
  getSort,
  getPageSize,
  prefetchedData,
  datasetId,
  filterResolver,
  getSchema,
  shouldAllowWixDataAccess
}) => {
  const EMPTY_SEED = Maybe.Nothing()
  const seed = QueryResults.fromWixDataQueryResults(
    prefetchedData,
    0
  ).matchWith({
    Empty: Maybe.Nothing,
    Results: flow(QueryResults.of, Maybe.Just)
  })

  return byRefField => {
    const pageSize = getPageSize()
    const allowWixDataAccess = shouldAllowWixDataAccess()

    return recordStoreService.chain(service => {
      if (byRefField) {
        return Result.fromMaybe(
          getSchema().map(schema =>
            getFieldReferencedCollection(byRefField, schema)
          ),
          `cannot resolve referenced collection name for field ${byRefField}`
        ).map(referencedCollectionName => {
          return service({
            pageSize,
            sort: null,
            filter: null,
            allowWixDataAccess,
            seed: EMPTY_SEED,
            datasetId,
            referencedCollectionName
          })
        })
      } else {
        const unresolvedFilter = getFilter()

        return Result.fromMaybe(
          filterResolver(unresolvedFilter).map(filter =>
            service({
              pageSize,
              sort: getSort(),
              filter,
              allowWixDataAccess,
              seed,
              datasetId,
              referencedCollectionName: null
            })
          ),
          'could not resolve dynamic filter'
        )
      }
    })
  }
}

export { isSameRecord, createRecordStoreService, createRecordStoreInstance }
